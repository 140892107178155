import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const AuthorSection = ({
  slug = "#",
  author = "",
  author_link = "",
  curated_by = "",
  created_on = "",
  hide_date = false,
}) => {
  if (curated_by) {
    return (
      <div className="mb-3">
        {curated_by.map((x, id) => (
          <div
            className="d-flex justify-content-center blog-author mb-1"
            key={`${slug} ${id}`}
          >
            <div>{x.title}</div>
            <div>:</div>
            <div>{x.desc}</div>
          </div>
        ))}
      </div>
    )
  }

  if (hide_date) {
    if (author_link) {
      return (
        <p className="text-center blog-author">
          By <a href={author_link} target="_blank">{author}</a>
        </p>
      )
    }
    return (
      <p className="text-center blog-author">
        By {author}
      </p>
    )
  }
  return (
    <p className="text-center blog-author">
      By {author}, {created_on}
    </p>
  )
}

const BlogItem = ({
  conClass = "col-lg-4 mb-5",
  title = "",
  image,
  short_desc = "",
  slug = "#",
  created_on = "",
  author = "",
  author_link = "",
  curated_by = "",
  hide_date = false,
}) => {
  const blogLink = `/blog-detail/${slug}/`;

  return (
    <div className={conClass}>
      <div className="blog-card">
        <div>
          <div className="blog-card-img-holder border-bottom">
            <Link className="text-body" to={blogLink}>
              {image &&
                image.childImageSharp &&
                image.childImageSharp.fluid && (
                  <Img
                    className="blog-card-img"
                    fluid={image.childImageSharp.fluid}
                    alt={title}
                  />
                )}
            </Link>
          </div>
          <div className="blog-card-body">
            <Link className="text-body" to={blogLink}>
              <h5 className="text-center mb-1">{title}</h5>
            </Link>
            <AuthorSection slug={slug} author={author} author_link={author_link} curated_by={curated_by} created_on={created_on} hide_date={hide_date} />
            <p className="blog-card-desc text-justify">{short_desc}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-4 pt-4">
          <Link to={blogLink} className="btn btn-blog">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
